import { Controller } from 'stimulus';

export default class extends Controller {
  initialize () {
    $(this.element).delay(8000).fadeOut(400)
  }

  close(){
    $(this.element).hide()
  }
}
